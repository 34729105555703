import type { StoreDetailsPayload } from './getStoreDetailsPayload';
import type { BreadcrumbPayload } from './useBreadcrumbPayload';
import type { BaseEventPayload } from './useGetBaseEventPayload';

export type Module =
  | 'Profile Management'
  | 'Home'
  | 'Browse'
  | 'Search'
  | 'Browse Detail'
  | 'Quick Shop'
  | 'Browse Detail Paused'
  | 'Browse Detail Missing'
  | 'Cart';

export enum ScreenName {
  Home = 'HOME',
  Browse = 'BROWSE',
  Search = 'SEARCH',
  BrowseDetail = 'BROWSE_DETAIL',
  QuickShopDetail = 'QUICK_SHOP_DETAIL',
  ProfileManagement = 'PROFILE_MANAGEMENT',
  Checkout = 'ViewCart',
  CheckoutFromHashId = 'CartStatus',
  CheckoutConfirmed = 'CartConfirmation',
  BrowseMissing = '404',
  BrowsePaused = 'BROWSE_PAUSED',
}

export enum ImageCarouselScrollVarant {
  Carousel = 'carousel',
  Lightbox = 'lightbox',
}

type SignUpEventPayload = BaseEventPayload &
  BreadcrumbPayload &
  StoreDetailsPayload & {
    'user type': 'BUYER';
  };

type StorefrontViewEventPayload = BaseEventPayload & BreadcrumbPayload & StoreDetailsPayload;
type QuickShopViewEventPayload = BaseEventPayload &
  BreadcrumbPayload &
  StoreDetailsPayload & {
    'item name': string; // catalogItem.name,
    'price per item': number; // catalogItem.price,
  };

type QuickShopRequestItemEventPayload = BaseEventPayload &
  BreadcrumbPayload &
  StoreDetailsPayload & {
    'item name': string;
    'price per item': number;
    quantity: number;
    note: string;
    'fulfillment method'?: string;
  };

type SignUpCompleteEventPayload = BaseEventPayload & BreadcrumbPayload & StoreDetailsPayload;

type HomeViewEventPayload = BaseEventPayload;

type SearchStoreEventPayload = BaseEventPayload & BreadcrumbPayload;

type BrowseCategoryEventPayload = BaseEventPayload & BreadcrumbPayload;

type StoreDetailsIcebreakerClickEventPayload = BaseEventPayload &
  BreadcrumbPayload &
  StoreDetailsPayload;

export type ScrollImageCarouselEventPayload = {
  isWeb: true;
  index: number;
  screen?: ScreenName;
  type: ImageCarouselScrollVarant;
  storefrontId: number;
  storefrontName: string;
};

export enum EventName {
  StorefrontView = 'View Storefront',
  QuickShopView = 'QuickShop View',
  QuickShopItemRequest = 'Quickshop Request Item',
  GetInTouch = 'Get in touch',
  SignUp = 'Sign Up',
  SignUpComplete = 'Sign Up Complete',
  HomeView = 'Home Screen View',
  SearchStores = 'Search for Storefront',
  BrowseCategory = 'Browse Category',
  StoreDetailsIcebreakerClick = 'Storefront Details Icebreaker Clicked',

  InvoiceIconClick = 'Invoice Icon Click',
  InvoiceClick = 'Invoice Click',
  ImageScroll = 'Image Scroll',
}

export type PayloadByEventName = {
  [EventName.StorefrontView]: StorefrontViewEventPayload;
  [EventName.QuickShopView]: QuickShopViewEventPayload;
  [EventName.QuickShopItemRequest]: QuickShopRequestItemEventPayload;
  [EventName.SignUp]: SignUpEventPayload;
  [EventName.SignUpComplete]: SignUpCompleteEventPayload;
  [EventName.HomeView]: HomeViewEventPayload;
  [EventName.SearchStores]: SearchStoreEventPayload;
  [EventName.BrowseCategory]: BrowseCategoryEventPayload;
  [EventName.StoreDetailsIcebreakerClick]: StoreDetailsIcebreakerClickEventPayload;
  [EventName.ImageScroll]: ScrollImageCarouselEventPayload;
  [EventName.InvoiceIconClick]: { isWeb: true };
  [EventName.InvoiceClick]: {
    isWeb: true;
    invoiceId: number;
    buyerName: string;
    businessName: string;
    sellerName: string;
  };
  [EventName.ImageScroll]: ScrollImageCarouselEventPayload;
};
